<template>
  <div class="chart-title" :class="{ 'chart-title-border': showBorder }">
    <div v-if="title">
      <div v-if="!hint" class="title">{{ title }}</div>
      <FormItemTooltipLabel
        v-else
        :label="title"
        :tooltipWidth="200"
        class="title"
      >
        {{ hint }}
      </FormItemTooltipLabel>
    </div>
    <div v-else />

    <div class="flex gap-4">
      <div class="edit-tags-btn" @click="openModal">編輯圖表標籤</div>
      <div v-if="selectOptions && useRange" class="date-select">
        <BaseElSelect
          v-model="currentOption"
          style="width: 100%"
          @change="selectDate"
        >
          <BaseElSelectOption
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <div v-if="useRangeType && currentOption !== '30'" class="switch-group">
          <div
            class="switch-btn"
            :class="{ 'active-btn': rangeType === 'week' }"
            @click="selectRange('week')"
          >
            週檢視
          </div>
          <div
            class="switch-btn"
            :class="{ 'active-btn': rangeType === 'month' }"
            @click="selectRange('month')"
          >
            月檢視
          </div>
        </div>
      </div>
    </div>
    <MemberTagAdvanceSelect
      :show.sync="showTagModal"
      :max="maxTags"
      :options="tags"
      :modelValue="currentTags"
      @update:modelValue="updateTagsGetChart"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import MemberTagAdvanceSelect from '@/components/Select/MemberTagAdvanceSelect.vue'
import { useDateSelect } from '@/use/dashboard'

export default defineComponent({
  name: 'TagsChartTitle',
  components: { FormItemTooltipLabel, MemberTagAdvanceSelect },
  props: {
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    showBorder: { type: Boolean, default: true },
    maxTags: { type: Number, default: 1 },
    currentTags: { type: Array, default: () => [] },
    updateTags: { type: Function, default: () => {} },
    tags: { type: Array, default: () => [] },
    useRangeType: { type: Boolean, default: true },
    useRange: { type: Boolean, default: false },
    getChart: { type: Function, default: () => {} },
  },
  setup (props) {
    const {
      selectOptions,
      currentOption,
      rangeType,
      dateSelectHandler,
      rangeTypeHandler,
    } = useDateSelect(props.useRangeType)

    const showTagModal = ref(false)

    const openModal = () => {
      showTagModal.value = true
    }

    const selectDate = (val) => {
      dateSelectHandler(val)
      props.getChart({
        interval: currentOption.value,
        splitType: rangeType.value,
      })
    }

    const selectRange = (val) => {
      rangeTypeHandler(val)
      props.getChart({
        interval: currentOption.value,
        splitType: rangeType.value,
      })
    }

    const updateTagsGetChart = async (e) => {
      props.updateTags(e)
      props.getChart({
        interval: currentOption.value,
        splitType: rangeType.value,
      })
    }

    onMounted(() => {
      props.getChart({
        interval: currentOption.value,
        splitType: rangeType.value,
      })
    })

    //  預設tag後重新渲染圖表
    watch(
      () => props.currentTags,
      (newOption) => {
        updateTagsGetChart(newOption)
      },
    )

    return {
      openModal,
      showTagModal,
      selectOptions,
      currentOption,
      rangeType,
      selectDate,
      selectRange,
      updateTagsGetChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.chart-title {
  @apply flex justify-between;
  @apply pb-5;
}
.chart-title-border {
  @apply border-b border-[#ececec];
}
.title {
  @apply text-[#636363];
}
::v-deep .date-select .el-input {
  @apply w-[150px];
}
.edit-tags-btn {
  @apply w-[124px] h-[36px] mr-4;
  @apply flex justify-center items-center;
  @apply text-[#165BAA];
  @apply border border-[#165BAA];
  @apply rounded;
  @apply cursor-pointer;
}

.switch-group {
  @apply flex;
  @apply mt-5;
  @apply border border-[#b3b3b3];
  @apply rounded-md;
  @apply overflow-hidden;
}

.switch-btn {
  @apply text-sm;
  @apply px-5 py-1;
  @apply cursor-pointer;
}

.active-btn {
  @apply text-[#fff];
  @apply bg-[#204bb4];
}

.date-select {
  @apply flex flex-col items-end;
}
</style>
